import React, { useCallback, useEffect, useState, useRef, useContext } from 'react';
import {
	type LoomVideo,
	type SDKState,
	type SDKUnsupportedReasons,
	type SDKButtonInterface,
} from '@loomhq/record-sdk';
import { defineMessages, FormattedMessage } from 'react-intl-next';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { useDialogs } from '@confluence/dialogs/entry-points/useDialogs';
import { fg } from '@confluence/feature-gating';
import { useSessionData } from '@confluence/session-data';
import type { ExperienceAttributes } from '@confluence/experience-tracker';
import { ExperienceTrackerContext, RECORD_LOOM_ENTRY_POINT } from '@confluence/experience-tracker';
import type { FlagsStateContainer } from '@confluence/flags';
import { isConfluenceRedirectToLoomSDKExperimentEnabled } from '@confluence/growth-experiment-helpers';

import type { NonMasteredUserModalProps } from './nonMasteredUserModal/NonMasteredUserModal';
import { NonMasteredUserModalLoader } from './nonMasteredUserModal/NonMasteredUserModalLoader';
import { OPEN_LOOM_RECORDER_SDK_INTERACTION_METRIC } from './perf.config';
import { useLoomEntryPointGlobalState } from './useLoomEntryPointGlobalState';
import type { StateType } from './useLoomEntryPointGlobalState';
import { useLoomEntryPointVariant } from './useLoomEntryPointVariant';
import { useLoomRecorderLoadingBlanket } from './useLoomRecorderLoadingBlanket';

export const i18n = defineMessages({
	errorTitleCannotStart: {
		id: 'loom-utils.error-flag.title.cannot-start',
		defaultMessage: 'We couldn’t start your recording',
		description:
			'title for a notification flag when a user encounters an error that prevents SDK from starting',
	},
	errorTitleRecordingAlreadyInProgress: {
		id: 'loom-utils.error-flag.title.recording-already-in-progress',
		defaultMessage: 'Recording already in progress',
		description:
			'title for a notification flag when a user encounters an error because a recording is already in progress',
	},
	errorTitleNotReady: {
		id: 'loom-utils.error-flag.title.not-ready',
		defaultMessage: 'The Loom recorder wasn’t ready',
		description: 'title for a notification flag when SDK is not ready to record',
	},
	errorIncompatibleBrowser: {
		id: 'loom-utils.error-flag.description.incompatible-browser',
		defaultMessage:
			'The browser you’re currently using isn’t compatible with Loom (or you could be trying to use it in private/incognito mode). <a>Which browsers are compatible?</a>',
		description:
			'description for an error flag when a user encounters a Loom SDK incompatible browser error',
	},
	errorThirdPartyCookieDisabled: {
		id: 'loom-utils.error-flag.description.third-party-cookie-disabled',
		defaultMessage: 'Check that you have third-party cookies enabled, then try again.',
		description:
			'description for an error flag when a user encounters a Loom SDK third-party cookie disabled error',
	},
	errorNoMediaStreamSupport: {
		id: 'loom-utils.error-flag.description.no-media-stream-support',
		defaultMessage:
			'You might need to try a different browser. <a>Which browsers are compatible?</a>',
		description:
			'description for an error flag when a user encounters a Loom SDK no media stream support error',
	},
	errorSDKNotInitialized: {
		id: 'loom-utils.error-flag.description.sdk-not-initialized',
		defaultMessage: 'Try to start recording again now.',
		description: 'description for an error flag when Loom SDK is not yet initialized',
	},
	errorBackendFFNotEnabledYet: {
		id: 'loom-utils.error-flag.description.be-ff-not-enabled-yet',
		defaultMessage: 'Loom was recently enabled. Try again in a few minutes.',
		description:
			'When the integration between Loom and Confluence is first enabled, the server and frontend code arent initially in sync. If the frontend is enabled before the server and a user tries to record a Loom, we will show an error flag with this description, and the issue should resolve on its own within a few minutes',
	},
	errorUnexpectedAuthTokenFailure: {
		id: 'loom-utils.error-flag.description.unexpected-auth-token-failure',
		defaultMessage:
			'Something went wrong with the Loom recorder. Try again later, or contact Atlassian Support for help.',
		description:
			'description for an error flag when the user tries to record a Loom but the auth token fails unexpectedly. We dont have a specific error message to show the user, so we show this generic message instead.',
	},
	errorLoomUserNotCreatedYet: {
		id: 'loom-utils.error-flag.description.loom-user-not-created-yet',
		defaultMessage:
			'Loom is enabled, just give us a few minutes to set everything up. You can try recording again in a bit.',
		description:
			'description for an error flag when the user tries to record a Loom but the user hasnt been created on Looms side yet. It can take a couple of minutes to create, so it will probably work if they try again later.',
	},
	errorSDKFailedToInitialize: {
		id: 'loom-utils.error-flag.description.sdk-failed-to-initialize',
		defaultMessage: 'Give it a moment and then refresh to try again.',
		description: 'description for an error flag when Loom SDK fails to initialize',
	},
	errorRecordingAlreadyInProgress: {
		id: 'loom-utils.error-flag.description.recording-already-in-progress',
		defaultMessage: 'Finish your current Loom video so you can start another.',
		description: 'description for an error flag when a recording is already in progress',
	},
});

export type OpenLoomRecorderParams = {
	onInsert: (loomVideoUrl: string, video: LoomVideo) => void;
	insertButtonText: string;
};

type useLoomRecorderEntryPointResponse = {
	isLoomRecorderInitialized: boolean;
	hasRecordingAccess: boolean;
	isOpeningRecorder: boolean;
	openLoomRecorder: (params?: OpenLoomRecorderParams) => Promise<void>;
};

export type UseLoomRecorderEntryPointProps = {
	entryPointLocation: string;
	resetInsertFunctionOnUnmount?: boolean;
	flags: FlagsStateContainer;
	experienceTrackerAttributes?: ExperienceAttributes;
};

type UseLoomRecorderEntryPointType = (
	props: UseLoomRecorderEntryPointProps,
) => useLoomRecorderEntryPointResponse;

const LoomSDKSupportLink = 'https://support.loom.com/hc/en-us/articles/360020667617-loomSDK-FAQ';

function getUnsupportedSDKErrorDescription(error: SDKUnsupportedReasons | undefined) {
	if (error === 'third-party-cookies-disabled') {
		return <FormattedMessage {...i18n.errorThirdPartyCookieDisabled} />;
	} else if (error === 'no-media-streams-support') {
		return (
			<FormattedMessage
				{...i18n.errorNoMediaStreamSupport}
				values={{
					a: (chunks: React.ReactNode[]) => (
						<a href={LoomSDKSupportLink} target="_blank">
							{chunks}
						</a>
					),
				}}
			/>
		);
	} else if (error === 'incompatible-browser') {
		return (
			<FormattedMessage
				{...i18n.errorIncompatibleBrowser}
				values={{
					a: (chunks: React.ReactNode[]) => (
						<a href={LoomSDKSupportLink} target="_blank">
							{chunks}
						</a>
					),
				}}
			/>
		);
	}
}

export const useLoomRecorderEntryPoint: UseLoomRecorderEntryPointType = ({
	entryPointLocation,
	flags,
	resetInsertFunctionOnUnmount = false,
	experienceTrackerAttributes,
}) => {
	const experienceTracker = useContext(ExperienceTrackerContext);
	const { cloudId, environment } = useSessionData();
	const { entryPointVariant } = useLoomEntryPointVariant();

	const [singletonLoomSDK, { initLoomSDK }] = useLoomEntryPointGlobalState();
	const [isOpeningRecorder, setIsOpeningRecorder] = useState(false);
	const runningSDKButton = useRef<SDKButtonInterface | null>(null);

	const [, { hideBlanket }] = useLoomRecorderLoadingBlanket();

	// the call site for openLoomRecorder might be outside of React lifecycle
	// (e.g. editor DOM button onclick) so we need to ensure we maintain reference
	const singletonLoomSDKRef = useRef<StateType>(singletonLoomSDK);
	singletonLoomSDKRef.current = singletonLoomSDK;

	const { showModal } = useDialogs();

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const isConfluenceRedirectToLoomSDKExperiment =
		isConfluenceRedirectToLoomSDKExperimentEnabled().isVariantCohort;

	useEffect(() => {
		if (entryPointVariant === 'BETA' || entryPointVariant === 'CO_USE') {
			void initLoomSDK({
				cloudId,
				environment,
			});
		}
	}, [initLoomSDK, cloudId, environment, entryPointVariant]);

	useEffect(() => {
		return () => {
			if (
				resetInsertFunctionOnUnmount &&
				runningSDKButton.current &&
				singletonLoomSDKRef.current.initializedLoomSDK
			) {
				singletonLoomSDKRef.current.initializedLoomSDK.updateConfig({
					config: {
						insertButtonText: 'Open on Loom',
					},
				});

				runningSDKButton.current.off('insert-click');
				runningSDKButton.current.on('insert-click', (video: LoomVideo) => {
					window.open(video.sharedUrl, '_blank');
					experienceTracker.succeed({
						name: RECORD_LOOM_ENTRY_POINT,
						attributes: {
							inserted: true,
							insertFunctionWasReset: true,
						},
					});

					if (fg('add-missing-loom-events')) {
						/**
						 * Why do we need to add sendUIEvent if we are already sending a experienceTracker.succeed(ui taskSuccess) event?
						 * "ui taskSuccess" does not have Statsig as destination, so we need to send a sendUIEvent to Statsig
						 */
						createAnalyticsEvent({
							type: 'sendUIEvent',
							data: {
								source: entryPointLocation,
								action: 'clicked',
								actionSubject: 'button',
								actionSubjectId: 'insertVideoLink',
								attributes: {
									insertFunctionWasReset: true,
									isConfluenceRedirectToLoomSDKExperimentEnabled:
										isConfluenceRedirectToLoomSDKExperiment,
								},
							},
						}).fire();
					}
				});
			}
		};
	}, [
		entryPointLocation,
		resetInsertFunctionOnUnmount,
		experienceTracker,
		createAnalyticsEvent,
		isConfluenceRedirectToLoomSDKExperiment,
	]);

	const openLoomRecorder = useCallback(
		async (loomRecorderParams?: OpenLoomRecorderParams) => {
			if (
				singletonLoomSDKRef.current.initializedLoomSDK &&
				singletonLoomSDKRef.current.initializedLoomSDK.status().state === 'active-recording'
			) {
				void hideBlanket();
				void flags.showInfoFlag({
					title: <FormattedMessage {...i18n.errorTitleRecordingAlreadyInProgress} />,
					description: <FormattedMessage {...i18n.errorRecordingAlreadyInProgress} />,
				});

				return;
			}

			experienceTracker.start({
				name: RECORD_LOOM_ENTRY_POINT,
				attributes: {
					entryPointLocation,
					isConfluenceRedirectToLoomSDKExperimentEnabled: isConfluenceRedirectToLoomSDKExperiment,
					...experienceTrackerAttributes,
				},
			});
			if (singletonLoomSDKRef.current.isInitializing) {
				void hideBlanket();
				void flags.showInfoFlag({
					title: <FormattedMessage {...i18n.errorTitleNotReady} />,
					description: <FormattedMessage {...i18n.errorSDKNotInitialized} />,
				});

				experienceTracker.abort({
					name: RECORD_LOOM_ENTRY_POINT,
					reason: 'SDK has not been configured yet.',
				});
				return;
			}
			// TODO CTIA- 2019: fire a clicked UI event here
			if (singletonLoomSDKRef.current.isLoomSDKSupportedError) {
				void hideBlanket();
				const description = getUnsupportedSDKErrorDescription(
					singletonLoomSDKRef.current.isLoomSDKSupportedError,
				);

				void flags.showInfoFlag({
					title: <FormattedMessage {...i18n.errorTitleCannotStart} />,
					description,
				});

				experienceTracker.abort({
					name: RECORD_LOOM_ENTRY_POINT,
					reason: `SDK not supported: ${singletonLoomSDKRef.current.isLoomSDKSupportedError}.`,
				});
				return;
			}
			if (!singletonLoomSDKRef.current.hasRecordingAccess) {
				void hideBlanket();
				const noRecordingAccessReason = singletonLoomSDKRef.current.noRecordingAccessReason;
				if (noRecordingAccessReason === 'backendFF') {
					void flags.showInfoFlag({
						title: <FormattedMessage {...i18n.errorTitleCannotStart} />,
						description: <FormattedMessage {...i18n.errorBackendFFNotEnabledYet} />,
					});

					experienceTracker.abort({
						name: RECORD_LOOM_ENTRY_POINT,
						reason: 'loom enablement in progress',
					});
				} else if (noRecordingAccessReason === 'not-created') {
					void flags.showInfoFlag({
						title: <FormattedMessage {...i18n.errorTitleCannotStart} />,
						description: <FormattedMessage {...i18n.errorLoomUserNotCreatedYet} />,
					});

					experienceTracker.abort({
						name: RECORD_LOOM_ENTRY_POINT,
						reason: 'user not created yet',
					});
				} else if (
					noRecordingAccessReason === 'not-mastered' ||
					noRecordingAccessReason === 'not-mastered-enterprise'
				) {
					// mastering for enterprise users is not available until M3
					const isMasteringFlowEnabledForEdition =
						noRecordingAccessReason !== 'not-mastered-enterprise';

					showModal<NonMasteredUserModalProps>(NonMasteredUserModalLoader, {
						isMasteringFlowEnabled:
							isMasteringFlowEnabledForEdition && fg('confluence_loom_beta_user_mastering_flow'),
					});
					experienceTracker.abort({
						name: RECORD_LOOM_ENTRY_POINT,
						reason: 'Does not have access to record looms from Confluence.',
						attributes: {
							noRecordingAccessReason,
						},
					});
				} else {
					void flags.showInfoFlag({
						title: <FormattedMessage {...i18n.errorTitleCannotStart} />,
						description: <FormattedMessage {...i18n.errorUnexpectedAuthTokenFailure} />,
					});

					experienceTracker.fail({
						name: RECORD_LOOM_ENTRY_POINT,
						error:
							singletonLoomSDKRef.current.authTokenError ||
							new Error('unexpected error from Loom auth token or Loom user status'),
					});
				}
				return;
			}
			if (
				!singletonLoomSDKRef.current.initializedLoomSDK ||
				!singletonLoomSDKRef.current.initializedLoomSDK.status().success
			) {
				void hideBlanket();
				void flags.showInfoFlag({
					title: <FormattedMessage {...i18n.errorTitleCannotStart} />,
					description: <FormattedMessage {...i18n.errorSDKFailedToInitialize} />,
				});

				experienceTracker.fail({
					name: RECORD_LOOM_ENTRY_POINT,
					error: new Error('Loom SDK did not successfully initialize.'),
				});

				return;
			}
			OPEN_LOOM_RECORDER_SDK_INTERACTION_METRIC.start();
			setIsOpeningRecorder(true);
			const { configureButton, updateConfig } = singletonLoomSDKRef.current.initializedLoomSDK;

			const sdkButton = configureButton();
			updateConfig({
				config: {
					insertButtonText: loomRecorderParams ? loomRecorderParams.insertButtonText : undefined,
					entryPointName: entryPointLocation,
				},
			});

			if (loomRecorderParams) {
				sdkButton.on('insert-click', async (video: LoomVideo) => {
					// TODO CTIA-2019: fire a inserted track event here
					loomRecorderParams.onInsert(video.sharedUrl, video);

					experienceTracker.succeed({
						name: RECORD_LOOM_ENTRY_POINT,
						attributes: {
							inserted: true,
						},
					});
					if (fg('add-missing-loom-events')) {
						/**
						 * Why do we need to add sendUIEvent if we are already sending a experienceTracker.succeed(ui taskSuccess) event?
						 * "ui taskSuccess" does not have Statsig as destination, so we need to send a sendUIEvent to Statsig
						 */
						createAnalyticsEvent({
							type: 'sendUIEvent',
							data: {
								source: entryPointLocation,
								action: 'clicked',
								actionSubject: 'button',
								actionSubjectId: 'insertVideoLink',
								attributes: {
									insertFunctionWasReset: false,
									isConfluenceRedirectToLoomSDKExperimentEnabled:
										isConfluenceRedirectToLoomSDKExperiment,
								},
							},
						}).fire();
					}
				});
			}

			if (fg('add-missing-loom-events')) {
				sdkButton.on('start', () => {
					createAnalyticsEvent({
						type: 'sendUIEvent',
						data: {
							source: entryPointLocation,
							action: 'clicked',
							actionSubject: 'button',
							actionSubjectId: 'loomRecordingStart',
							attributes: {
								isConfluenceRedirectToLoomSDKExperimentEnabled:
									isConfluenceRedirectToLoomSDKExperiment,
							},
						},
					}).fire();
				});

				sdkButton.on('recording-start', () => {
					createAnalyticsEvent({
						type: 'sendTrackEvent',
						data: {
							source: entryPointLocation,
							action: 'started',
							actionSubject: 'loomRecording',
							attributes: {
								isConfluenceRedirectToLoomSDKExperimentEnabled:
									isConfluenceRedirectToLoomSDKExperiment,
							},
						},
					}).fire();
				});

				sdkButton.on('cancel', () => {
					createAnalyticsEvent({
						type: 'sendUIEvent',
						data: {
							source: entryPointLocation,
							action: 'clicked',
							actionSubject: 'button',
							actionSubjectId: 'loomRecordingCancel',
							attributes: {
								isConfluenceRedirectToLoomSDKExperimentEnabled:
									isConfluenceRedirectToLoomSDKExperiment,
							},
						},
					}).fire();
				});

				sdkButton.on('complete', () => {
					createAnalyticsEvent({
						type: 'sendUIEvent',
						data: {
							source: entryPointLocation,
							action: 'clicked',
							actionSubject: 'button',
							actionSubjectId: 'loomRecordingComplete',
							attributes: {
								isConfluenceRedirectToLoomSDKExperimentEnabled:
									isConfluenceRedirectToLoomSDKExperiment,
							},
						},
					}).fire();
				});

				sdkButton.on('recording-complete', () => {
					createAnalyticsEvent({
						type: 'sendTrackEvent',
						data: {
							source: entryPointLocation,
							action: 'completed',
							actionSubject: 'loomRecording',
							attributes: {
								isConfluenceRedirectToLoomSDKExperimentEnabled:
									isConfluenceRedirectToLoomSDKExperiment,
							},
						},
					}).fire();
				});

				sdkButton.on('upload-complete', () => {
					createAnalyticsEvent({
						type: 'sendTrackEvent',
						data: {
							source: entryPointLocation,
							action: 'uploaded',
							actionSubject: 'loomRecording',
							attributes: {
								isConfluenceRedirectToLoomSDKExperimentEnabled:
									isConfluenceRedirectToLoomSDKExperiment,
							},
						},
					}).fire();
				});
			}

			sdkButton.on('lifecycle-update', (lifecycleState: SDKState) => {
				if (lifecycleState === 'pre-recording') {
					OPEN_LOOM_RECORDER_SDK_INTERACTION_METRIC.stop({
						customData: {
							isConfluenceRedirectToLoomSDKExperimentEnabled:
								isConfluenceRedirectToLoomSDKExperiment,
						},
					});
					setIsOpeningRecorder(false);
					void hideBlanket();
				} else if (lifecycleState === 'closed') {
					runningSDKButton.current = null;
					experienceTracker.succeed({
						name: RECORD_LOOM_ENTRY_POINT,
						attributes: {
							closed: true,
							insertFunctionDefined: loomRecorderParams !== undefined,
						},
					});
				}
			});

			sdkButton.openPreRecordPanel();
			runningSDKButton.current = sdkButton;
		},
		[
			entryPointLocation,
			experienceTracker,
			flags,
			showModal,
			hideBlanket,
			experienceTrackerAttributes,
			createAnalyticsEvent,
			isConfluenceRedirectToLoomSDKExperiment,
		],
	);

	return {
		openLoomRecorder,
		isOpeningRecorder,
		isLoomRecorderInitialized: !singletonLoomSDKRef.current.isInitializing,
		hasRecordingAccess: singletonLoomSDKRef.current.hasRecordingAccess,
	};
};
